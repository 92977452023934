.tbl {
	width: 70%;
	border-collapse: separate;
	border-radius: 0.5rem;
	background: #5d5d5d;
}

.tbl > * > tr > td{
	padding: 0.4rem;
	text-align: center;
}
