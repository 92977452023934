.box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
	height: 100dvh;
}

.coin {
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 2rem;
	width: 10rem;
	height: 10rem;
	background: #b55b07;
	border-radius: 50%;
	border: 0.5rem solid #f87d09;
	animation: spin 2s ease-in-out infinite;
}

@keyframes spin {
	to {
		transform: rotateY(720deg);
	}
}

