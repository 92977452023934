@font-face {
	font-family: 'mono45-headline';
	src: local('Mono45 Headline Regular'), local('Mono45-Headline-Regular'),
		url('./fonts/Mono45Headline/Mono45Headline-Regular.woff2') format('woff2'),
		url('./fonts/Mono45Headline/Mono45Headline-Regular.woff') format('woff'),
		url('./fonts/Mono45Headline/Mono45Headline-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-size: 1rem;
	font-family: 'mono45-headline', Arial, sans-serif;
	font-weight: 600;
	letter-spacing: 0.08rem;
}

html {
	font-size: 20px;
}

*::-webkit-scrollbar {
	display: none;
}

input:focus, textarea:focus, select:focus {
	outline: none;
}

body {
	background: #1b1b1e;
	color: #dbdbe7;
}

.main-container {
	display: flex;
	height: calc(100vh - 3rem);
	width: 100%;
}

.content, .chat {
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	overflow-y: scroll;
	border-radius: 0.5rem;
	background: #222225;
	padding: 1rem;
	margin: 1rem;
}

.content {
	width: 100%;
	align-items: center;	
}

.chat {
	width: 30%;
	margin-left: 0;
}

nav {
	height: 3rem;
	background: #222225;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

nav > div {
	display: flex;
	flex-direction: row;
	align-items: center;
}

nav > div > * {
	margin: 0 0.5rem;
	text-align: center;
}

.sidebar {
	width: 8%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #2A2A30;
}

.sidebar > * {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem;
	width: 100%;
}

.sidebar > *:hover {
	background: #F87D09;
}

form {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
}

table {
	width: 100%;
	border-radius: 0.5rem;
	background: #5d5d5d;
}

table td {
	text-align: center;
}

thead td {
	background: #b55b07;
}

thead td:first-of-type{
    border-top-left-radius: 0.5rem; 
}

thead td:last-of-type{
    border-top-right-radius: 0.5rem; 
}

@media only screen and (max-device-width: 600px) {
	html {
		font-size: 30px;
	}

	.main-container {
		flex-direction: column;
		align-items: center; 
		height: calc(100dvh - 3rem);
	}

	nav > div > * {
		margin: 0 0.1rem;
	}

	.sidebar {
		width: 100%;
		height: 3rem;
		flex-direction: row;
	}
	
	.sidebar > * {
		height: 100%;
	}

	.content, .chat {
		width: calc(100% - 2rem);
	}

	.content {
		height: 100%;
	}

	.chat {
		height: 50%;
		margin: 1em;
		margin-top: 0;
	}
}
