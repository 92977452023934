.dicePicker {
	display: flex;
}

.dicePicker > p {
	display: flex;
	justify-content: center;
	align-items: center;

	margin: 0.2rem;
	border: 0.15rem solid rgba(255, 255, 255, 0.16);
	background: #000000a3;
	border-radius: 0.5rem;
	width: 5rem;
}

.sp {
	font-size: 2rem;
}
