.field {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.row {
	display: flex;
}

.cell {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4rem;
	height: 4rem;
	border: 0.15rem solid #e8a566;
	border-radius: 0.5rem;
	background: #f87d09;
	cursor: pointer;
	transition: 0.2s;
	margin: 0.1rem;
}

.cell:disabled {
	cursor: not-allowed;
}

.picked {
	background-size: cover;
	background-image: url(../../assets/images/miner_icons/money.jpg);
}

.bomb {
	background: #ffffff;
	background-size: cover;
	background-image: url(../../assets/images/miner_icons/bomb.png);
}

.was_picked {
	background-size: cover;
	background-image: url(../../assets/images/miner_icons/money.jpg);
	opacity: 0.3;
}
