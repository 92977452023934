.reviewsList {
    height: 100%;
    width: 100%;
    overflow-wrap: anywhere;
	overflow-y: scroll;
}

.reviewsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
	background: #3d3c3c;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 0.5rem 0;
}
