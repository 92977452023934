.playerHand {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.playerHand > div {
	display: flex;
	flex-direction: row;
}
