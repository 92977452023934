.container {
	border: 0.15rem solid #F87D09;
	border-radius: 0.5rem;
	background: #00000088;
	width: 100%;
}

.container > div {
	text-align: center;
}
