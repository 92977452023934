.card {
	border: 0.15rem #f87d09 solid;
	border-radius: 0.5rem;

	width: 7rem;
	height: 9rem;
	text-align: center;
	margin-left: -0.6rem;
	margin-right: -0.6rem;
}
